<template>
     <div class="">
        <div class="padding fontS_16 font_weight_bold PB_3 PT_8 bg_fff">
            <van-icon @click="$router.go(-1)" size=".35rem" name="arrow-left"/>
            <div style="margin-left:2.8rem;margin-top:-0.1rem;">{{title}} </div> 
        </div>
        <div class="P_4">
            <!-- <div v-show="searchInputShow">
                <div class="flex_rowC  PB_3 relative">
                    <input type="text" @input="text($event)" class="PTB_2 B_1_F44C06 PL_4 B_radius_5 flex_grow_1" placeholder="请输入内容">
                    <van-icon name="search" size=".4rem" class="absolute color_F44C06" style="right: 5%" />
                </div>
            </div> -->
            <div v-if="filterList.length == 0" class="list">
                <div :class="item.status == true ? 'active' : 'item' " @click="searchMsg(index,item.type)"  v-for="(item,index) in dataList" :key="index">{{item.msg}}</div>
            </div>
            <div  class="list" v-else>
                <div :class="item.status == true ? 'active' : 'item' " @click="searchMsg(index,item.type)"  v-for="(item,index) in filterList" :key="index">{{item.msg}}</div>
            </div>
        </div>

    </div>
</template>

<script>
    import '@/assets/css/common.css';
 
     
    export default {
        name: "search",
        data() {
            return {
                title:'搜索',
                dataList:[{type:1,msg:'背调授权',status:false},
                {type:2,msg:'入职授权通知',status:false},
                {type:3,msg:'离职授权通知',status:false},
                {type:4,msg:'技能认可通知',status:false},
                {type:5,msg:'简历转介绍授权通知',status:false},
                {type:6,msg:'工作评价通知',status:false},
                {type:7,msg:'简历下载',status:false},],
                searchInputShow:true,
                filterList:[]
            }
        },
        created() {
            // console.log(this.$route.query.type);
            let type = this.$route.query.type;
            switch (type) {
                case '搜索' :
                    this.title = '搜索';
                    break;
                case '筛选' :
                    this.title = '筛选';
                    this.searchInputShow = false;
                    break;
            }
        },
        mounted() {
        },
        methods: {
            text(e){
                let data = e.data
                let list = this.dataList
                let arr = []
                for(var i=0;i<list.length;i++){
                    if(list[i].msg.indexOf(data)>=0){
                        arr.push(list[i])
                    }
                }
                // console.log(arr)
                this.filterList = arr
                // console.log(this.filterList)
            },
            searchMsg(index,type){
               for(var i =0;i<this.dataList.length;i++){
                   this.dataList[index].status = !this.dataList[index].status
               }
            //    sessionStorage.setItem("msgType4",type)
               this.$router.push({path:"/message",query:{type6:type}})
            },
        },
         
    }
</script>

<style scoped>
.padding{
    padding-left:0.3rem;
    display:flex;
}
.list{
    display:flex;
    flex-wrap: wrap;
    margin-top:0.46rem;
}
.item{
height: 0.7rem;
line-height: 0.7rem;
background: #FFEDE4;
border-radius: 0.4rem;
/* border: 0.01rem solid #979797; */
padding:0 0.3rem;
font-size: 0.28rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FF5F19;
margin-right:0.3rem;
margin-bottom:0.2rem;
}
.active{
    height: 0.7rem;
line-height: 0.7rem;
background: #FFFFFF;
border-radius: 0.4rem;
border: 0.01rem solid #F44C06;
padding:0 0.3rem;
font-size: 0.28rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #F44C06;
margin-right:0.3rem;
margin-bottom:0.2rem;
}
</style>